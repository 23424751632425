import React from "react"

function Paginacao({ pagina = 1, total = 0, tamanho = 0, onChange = () => {} }) {
	if (total == 0 || total <= tamanho) {
		return (
			<nav>
				<ul className="pagination">
					<li className="page-item disabled">
						<button type="button" className="page-link">
							Anterior
						</button>
					</li>
					<li className="page-item active">
						<button type="button" className="page-link btn-poupacred">
							1
						</button>
					</li>
					<li className="page-item disabled">
						<button type="button" className="page-link">
							Próximo
						</button>
					</li>
				</ul>
			</nav>
		)
	} else if (pagina == 1) {
		return (
			<nav>
				<ul className="pagination">
					<li className="page-item disabled">
						<button type="button" className="page-link">
							Anterior
						</button>
					</li>
					<li className="page-item active">
						<button type="button" className="page-link">
							1
						</button>
					</li>
					{Math.ceil(total / tamanho) >= 2 && (
						<li className="page-item">
							<button type="button" className="page-link" onClick={() => onChange(2)}>
								2
							</button>
						</li>
					)}
					{Math.ceil(total / tamanho) >= 3 && (
						<li className="page-item">
							<button type="button" className="page-link" onClick={() => onChange(3)}>
								3
							</button>
						</li>
					)}
					<li
						className={`page-item${Math.ceil(total / tamanho) >= 2 ? "" : " disabled"}`}
						onClick={() => {
							if (Math.ceil(total / tamanho) >= 2) {
								onChange(2)
							}
						}}
					>
						<button type="button" className="page-link">
							Próximo
						</button>
					</li>
				</ul>
			</nav>
		)
	} else if (Math.ceil(total / tamanho) == pagina) {
		return (
			<nav>
				<ul className="pagination">
					<li className="page-item">
						<button type="button" className="page-link" onClick={() => onChange(pagina - 1)}>
							Anterior
						</button>
					</li>
					{pagina - 2 > 0 && (
						<li className="page-item">
							<button type="button" className="page-link" onClick={() => onChange(pagina - 2)}>
								{pagina - 2}
							</button>
						</li>
					)}
					{pagina - 1 > 0 && (
						<li className="page-item">
							<button type="button" className="page-link" onClick={() => onChange(pagina - 1)}>
								{pagina - 1}
							</button>
						</li>
					)}
					<li className="page-item active">
						<button type="button" className="page-link">
							{pagina}
						</button>
					</li>
					<li className="page-item disabled">
						<button type="button" className="page-link">
							Próximo
						</button>
					</li>
				</ul>
			</nav>
		)
	} else {
		return (
			<nav>
				<ul className="pagination">
					<li className="page-item">
						<button type="button" className="page-link" onClick={() => onChange(pagina - 1)}>
							Anterior
						</button>
					</li>
					<li className="page-item">
						<button type="button" className="page-link" onClick={() => onChange(pagina - 1)}>
							{pagina - 1}
						</button>
					</li>
					<li className="page-item active">
						<button type="button" className="page-link">
							{pagina}
						</button>
					</li>
					<li className="page-item">
						<button type="button" className="page-link" onClick={() => onChange(pagina - 1)}>
							{pagina + 1}
						</button>
					</li>
					<li className="page-item">
						<button type="button" className="page-link" onClick={() => onChange(pagina + 1)}>
							Próximo
						</button>
					</li>
				</ul>
			</nav>
		)
	}
}

export default Paginacao
