import React from "react"

function ItensInformacoesEscobs(props) {
   const {collapseId, collapseNome, titulosTabela, valoresTabela} = props

   return (
      <div className="accordion-item">
         <div id={`heading${collapseId}`} className="accordion-header">
            <a
               href="#!"
               className="accordion-button collapsed"
               role="button"
               data-bs-toggle="collapse"
               data-bs-target={`#collapse${collapseId}`} 
               aria-expanded="false"
               aria-controls={`collapse${collapseId}`}
            >
               {collapseNome}
            </a>
         </div>
         <div
            id={`collapse${collapseId}`}
            className="accordion-collapse collapse show"
            aria-labelledby={`heading${collapseId}`}
            data-bs-parent="#accordionExample"
         >
            <div className="accordion-body">
               <table className="table">
                  <thead>
                     <tr>
                        {titulosTabela && 
                        titulosTabela.map(item => (
                           <th scope="col">{item.titulo}</th>
                        ))}
                     </tr>
                  </thead>
                  <tbody>
                     {valoresTabela.map(item => (
                        <tr>
                        {titulosTabela.map(titulos => (
                           <td>{item[titulos.label]}</td>
                        ))}
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}

export default ItensInformacoesEscobs