import React, { useEffect, useState } from "react"

function ContadorSessoes({ socket }) {
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const listenerContador = (x) => setTotal(x.total)
		socket.on("total-sessoes", listenerContador)
		socket.emit("get-total-sessoes")
		return () => {
			socket.off("total-sessoes", listenerContador)
		}
	}, [socket])

	return <h3 className="mb-0">{total}</h3>
}

export default ContadorSessoes
