import { BrowserRouter, Routes, Route } from "react-router-dom"
import Header from "./components/Header"
import Navbar from "./components/Navbar"
import Home from "./screens/Home"
import CSG from "./screens/CSG"
import { useSelector } from "react-redux"
import Login from "./screens/Login"
import Profile from "./screens/Profile"

function App() {
	const user = useSelector((state) => state.user)

	if (user && user.id == null) {
		return <Login />
	} else {
		return (
			<BrowserRouter>
				<Header />
				<Navbar />
				<Routes>
					<Route path="/" element={<CSG />} exact />
					<Route path="/operador" element={<CSG />} exact />
					<Route path="/perfil" element={<Profile />} exact />
				</Routes>
			</BrowserRouter>
		)
	}
}

export default App
