const { toast } = require("react-toastify")
const { LOGOUT } = require("./redux/Actions")
const { Store } = require("./redux/Store")
const { default: axios } = require("axios")
const { REACT_APP_API_URL, REACT_APP_APP_TOKEN, REACT_APP_API_MS_CRM } = process.env

const API = axios.create({
	baseURL: REACT_APP_API_URL,
	timeout: 60000,
	withCredentials: false,
	validateStatus: function (status) {
		if (status === 401) {
			if (!toast.isActive("session")) {
				toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
			}
			Store.dispatch({ type: LOGOUT })
		}
		return status >= 200 && status <= 201
	},
})

const API_CRM = axios.create({
	baseURL: REACT_APP_API_MS_CRM,
	timeout: 60000,
	withCredentials: false,
	validateStatus: function (status) {
		if (status === 401) {
			if (!toast.isActive("session")) {
				toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
			}
			Store.dispatch({ type: LOGOUT })
		}
		return status >= 200 && status <= 201
	},
})

API.interceptors.request.use((config) => {
	config.headers["Authorization"] = Store.getState().user.token
	config.headers["App-Token"] = REACT_APP_APP_TOKEN
	return config
})

const ERRO = (err) => {
	if (err.response && err.response.data) {
		if (err.response.status === 500) {
			toast.error(err.response.data)
		} else {
			toast.warn(err.response.data)
		}
	} else {
		toast.error("Erro de comunicação com o sistema!", { toastId: "tokenError" })
	}
}

const cap = (texto) => {
	return texto.charAt(0).toUpperCase() + texto.slice(1)
}

const formatarNome = (nome) => {
	if (nome) {
		const firstName = nome.split(" ").slice(0, -1).join(" ")
		const lastName = nome.split(" ").slice(-1).join(" ")
		return cap(firstName.toLowerCase()) + " " + cap(lastName.toLowerCase())
	} else {
		return ""
	}
}

const inicializarScripts = (window) => {
	window.$(".js-daterangepicker").daterangepicker()
	new window.HSFormSearch(".js-form-search")
	new window.HSSideNav(".js-navbar-vertical-aside").init()
	window.getHSCore().components.HSClipboard.init(".js-clipboard")
	window.getHSCore().components.HSTomSelect.init(".js-select")
	window.getHSCore().components.HSFlatpickr.init(".js-flatpickr")
	const list = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	list.map(function (tooltipTriggerEl) {
		return new window.bootstrap.Tooltip(tooltipTriggerEl)
	})
	window.$('[data-bs-toggle="tooltip"]').on("click", function () {
		window.$(this).tooltip("hide")
	})
}

const tipoArquivo = (tipo) => {
	switch (tipo.toLowerCase().trim()) {
		case "pdf":
			return "./assets/svg/brands/pdf-icon.svg"

		case "xls":
		case "xlsx":
		case "xlsm":
		case "xlsd":
		case "csv":
			return "./assets/svg/brands/google-sheets-icon.svg"

		case "doc":
		case "docx":
			return "./assets/svg/brands/google-docs-icon.svg"

		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
			return "./assets/svg/components/placeholder-img-format.svg"

		case "ppt":
		case "pptx":
			return "./assets/svg/brands/google-slides-icon.svg"
	}
}

// const ipUsuario = async () => {
// 	let ip = fetch('https://api.ipify.org?format=json')
// 		.then((response) => response.json())
// 		.then(function(data) {
// 			return data.ip || ""
// 		})
// 		.catch(function(error) {
// 			console.log(error)
// 		})

// 	return ip
// }

module.exports = { API, API_CRM, ERRO, formatarNome, inicializarScripts, tipoArquivo }
