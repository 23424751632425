import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { Store, Persistor } from "./redux/Store.js"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

ReactDOM.render(
	<React.StrictMode>
		<Provider store={Store}>
			<PersistGate loading={null} persistor={Persistor}>
				<App />
				<ToastContainer
					position={"top-right"}
					closeOnClick
					pauseOnHover
					pauseOnFocusLoss={false}
					rtl={false}
				/>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
//toast.configure()
