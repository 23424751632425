import React, { useEffect, useState } from "react"
import Countdown from "react-countdown"

function ContadorTempo({ socket }) {
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const listenerContador = (x) => setTotal(x.total)
		socket.on("total-desconexao", listenerContador)
		socket.emit("get-total-desconexao")
		return () => {
			socket.off("total-desconexao", listenerContador)
		}
	}, [socket])

	if (total == null || total.toString().length < 8) {
		return <h3 className="mb-0">0m 0s</h3>
	} else {
		return (
			<Countdown
				date={total}
				renderer={({ hours, minutes, seconds, completed }) => {
					if (completed) {
						return <h3 className="mb-0">0m 0s</h3>
					} else {
						return (
							<h3 className="mb-0 text-danger">
								{minutes}m {seconds}s
							</h3>
						)
					}
				}}
			/>
		)
	}
}

export default ContadorTempo
