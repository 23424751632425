import React, { useEffect, useState } from "react"

function ContadorOnline({ socket }) {
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const listenerContador = (x) => setTotal(x.total)
		socket.on("total-online", listenerContador)
		socket.emit("get-total-online")
		return () => {
			socket.off("total-online", listenerContador)
		}
	}, [socket])

	return <h3 className="mb-0">{total}</h3>
}

export default ContadorOnline
