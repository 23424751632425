import React from "react"

import ItensInformacoesEscobs from "./ItensInformacoesEscobs"

function InformacoesEscobs(props) {
   const { dados } = props

   const titulosInformacaoPessoal = [
      { label: "nome_pre", titulo: "Nome" },
      { label: "datanascimento_pre", titulo: "Data de nascimento" },
      { label: "cpf_pre", titulo: "CPF" },
      { label: "email_pre", titulo: "E-mail" },
      { label: "nomemae_pre", titulo: "Nome Mãe" },
      { label: "nomepai_pre", titulo: "Nome Pai" }
   ]

   const titulosHistoricoRecuperacao = [
      { label: "datahora_peh", titulo: "Data/Hora" },
      { label: "origem_peh", titulo: "Origem" },
      { label: "assunto_peh", titulo: "Assunto" },
   ]

   const titulosProposta = [
      { label: "dataproposta_pep", titulo: "Data proposta" },
      { label: "realizado_pep", titulo: "Realizado" },
      { label: "canal_pep", titulo: "Canal" },
      { label: "status_pep", titulo: "Status" }
   ]

   const titulosAcordo = [
      { label: "produto_pea", titulo: "Produto" },
      { label: "contratacaocancelamento_pea", titulo: "Contratação cancelamento" },
      { label: "contrato_pea", titulo: "Contrato" },
      { label: "valorcontratado_pea", titulo: "Valor contratado" },
      { label: "vencimento_pea", titulo: "Vencimento" },
      { label: "status_pea", titulo: "Status" }
   ]

   const titulosOperacaoInadimplente = [
      { label: "produto_poi", titulo: "Produto" },
      { label: "contratacaocancelamento_poi", titulo: "Contratação cancelamento" },
      { label: "contrato_poi", titulo: "Contrato" },
      { label: "valorcontratado_poi", titulo: "Valor contratado" },
      { label: "vencimento_poi", titulo: "Vencimento" },
      { label: "status_poi", titulo: "Status" }
   ]

   const titulosOperacaoCliente = [
      { label: "inicioatraso_poc", titulo: "Início do atraso" },
      { label: "diasatraso_poc", titulo: "Dias de atraso" },
      { label: "numerocontrato_poc", titulo: "Nº Contrato" },
      { label: "produto_poc", titulo: "Produto" },
      { label: "subproduto_poc", titulo: "Subproduto" },
      { label: "saldodevedor_poc", titulo: "Saldo devedor" }
   ]

   return (
      <div id="accordionEscobs" className="accordion">
         {/* Informações Pessoais */}
         {
            dados.prospect_escobs
               && Object.keys(dados.prospect_escobs).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="InformacaoPessoal"
                     collapseNome="Informação Pessoal"
                     titulosTabela={titulosInformacaoPessoal}
                     valoresTabela={dados.prospect_escobs}
                  />
               )
               : <></>
         }

         {/* Histórico Recuperação */}
         {
            dados.prospect_historico_recuperacao
               && Object.keys(dados.prospect_historico_recuperacao).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="HistoricoRecuperacao"
                     collapseNome="Histórico Recuperação"
                     titulosTabela={titulosHistoricoRecuperacao}
                     valoresTabela={dados.prospect_historico_recuperacao}
                  />
               )
               : <></>
         }

         {/* Proposta */}
         {
            dados.prospect_proposta
               && Object.keys(dados.prospect_proposta).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="Proposta"
                     collapseNome="Proposta"
                     titulosTabela={titulosProposta}
                     valoresTabela={dados.prospect_proposta}
                  />
               )
               : <></>
         }

         {/* Acordo */}
         {
            dados.prospect_acordo
               && Object.keys(dados.prospect_acordo).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="Acordo"
                     collapseNome="Acordo"
                     titulosTabela={titulosAcordo}
                     valoresTabela={dados.prospect_acordo}
                  />
               )
               : <></>
         }

         {/* Operação Inadimplente */}
         {
            dados.prospect_operacao_inadimplente
               && Object.keys(dados.prospect_operacao_inadimplente).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="OperacaoInadimplente"
                     collapseNome="Operação Inadimplente"
                     titulosTabela={titulosOperacaoInadimplente}
                     valoresTabela={dados.prospect_operacao_inadimplente}
                  />
               )
               : <></>
         }

         {/* Operação Cliente */}
         {
            dados.prospect_operacao_cliente
               && Object.keys(dados.prospect_operacao_cliente).length > 0
               ? (
                  <ItensInformacoesEscobs
                     collapseId="OperacaoCliente"
                     collapseNome="Operação Cliente"
                     titulosTabela={titulosOperacaoCliente}
                     valoresTabela={dados.prospect_operacao_cliente}
                  />
               )
               : <></>
         }
      </div>
   )
}

export default InformacoesEscobs