import { useState } from "react"
import { API, ERRO, formatarNome, inicializarScripts } from "../utils"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { UPDATE_USER } from "../redux/Actions"

function Login(props) {
	const [logando, setLogando] = useState(false)
	const [usuario, setUsuario] = useState("")
	const [senha, setSenha] = useState("")
	const dispatch = useDispatch()

	const logar = async (e) => {
		e.preventDefault()

		try {
			if (usuario == null || usuario.length < 3) {
				toast.warn("Informe um usuário válido...")
			} else if (senha == null || usuario.length < 3) {
				toast.warn("Informe uma senha válida...")
			} else {
				setLogando(true)							

				const response = await API.request({
					url: "/usuarios/login",
					method: "post",
					data: { usuario, senha },
				})

				if (response.data.usuario_logado) {
					toast.info("Este usuário já se encontra logado!")
					setLogando(false)
					return
				}

				toast.info(`${formatarNome(response.data.nome).split(" ")[0]}, seja bem-vindo(a) ao Poupanegócios!`)
				dispatch({ type: UPDATE_USER, payload: response.data })
			}
		} catch (err) {
			ERRO(err)
			setLogando(false)
		}
	}

	return (
		<main
			id="content"
			role="main"
			className="main"
			style={{ paddingLeft: 0 }}
			onLoad={() => inicializarScripts(window)}
		>
			<div
				className="position-fixed top-0 end-0 start-0 bg-img-start"
				style={{ height: "36rem", backgroundColor: "#fff" }}
				// style={{ height: "36rem", backgroundImage: "url(./assets/svg/components/card-6.svg)" }}
			>
				{/* Shape */}
				<div className="shape shape-bottom zi-1">
					<svg
						preserveAspectRatio="none"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						viewBox="0 0 1921 273"
					>
						<polygon fill="#FFF" points="0,273 1921,273 1921,0 " />
					</svg>
				</div>
				{/* End Shape */}
			</div>
			{/* Content */}
			<div className="container py-5 py-sm-7">
				<a className="d-flex justify-content-center mb-5" href="./index.html">
					<img
						className="zi-2"
						src="./assets/svg/logos/logo.png"
						alt="Image Description"
						style={{ width: "20rem" }}
					/>
				</a>
				<div className="mx-auto" style={{ maxWidth: "30rem" }}>
					{/* Card */}
					<div className="card card-lg mb-5">
						<div className="card-body">
							{/* Form */}
							<form className="js-validate needs-validation" onSubmit={logar} autoComplete={"on"}>
								<div className="text-center">
									<div className="mb-5">
										<h1 className="display-5">Poupanegócios</h1>
										<p>Entre usando suas credencias do Poupasistema.</p>
									</div>
								</div>
								{/* Form */}
								<div className="mb-4">
									<label className="form-label">Seu usuário</label>
									<input
										name="username"
										autoComplete={"on"}
										type="text"
										className="form-control form-control-lg"
										tabIndex={1}
										placeholder="nome.sobrenome"
										required
										disabled={logando}
										value={usuario}
										onChange={(e) => setUsuario(e.target.value.trim())}
									/>
									<span className="invalid-feedback">Informe um usuário válido.</span>
								</div>
								{/* End Form */}
								{/* Form */}
								<div className="mb-4">
									<label className="form-label w-100">
										<span className="d-flex justify-content-between align-items-center">
											<span>Sua senha</span>
										</span>
									</label>
									<div className="input-group input-group-merge" data-hs-validation-validate-class>
										<input
											name="password"
											autoComplete={"on"}
											type="password"
											className="js-toggle-password form-control form-control-lg"
											placeholder="senha"
											required
											disabled={logando}
											value={senha}
											tabIndex={2}
											onChange={(e) => setSenha(e.target.value.trim())}
											data-hs-toggle-password-options='{
                                                "target": "#changePassTarget",
                                                "defaultClass": "bi-eye-slash",
                                                "showClass": "bi-eye",
                                                "classChangeTarget": "#changePassIcon"
                                            }'
										/>
									</div>
									<span className="invalid-feedback">Informe uma senha válida.</span>
								</div>
								{/* End Form */}
								<div className="d-grid">
									{logando ? (
										<button className="btn btn-poupacred" type="button" disabled>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											/>
											{" Entrando..."}
										</button>
									) : (
										<button type="submit" className="btn btn-poupacred btn-lg" tabIndex={3}>
											Entrar
										</button>
									)}
								</div>
							</form>
							{/* End Form */}
						</div>
					</div>
					{/* End Card */}
				</div>
			</div>
			{/* End Content */}
		</main>
	)
}

export default Login
