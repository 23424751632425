import { combineReducers } from "redux"
import { setToken } from "../utils"

import { LOGOUT, UPDATE_USER } from "./Actions"

const userInitialStateReducer = {
	id: null,
	nome: null,
	foto: null,
	token: null,
	parceiro: null,
	email: null,
	expiracao: null,
}

const userReducer = (state = userInitialStateReducer, action) => {
	switch (action.type) {
		case UPDATE_USER:
			return {
				...state,
				id: action.payload.id,
				nome: action.payload.nome,
				foto: action.payload.foto,
				token: action.payload.token,
				email: action.payload.email,
				expiracao: action.payload.expiracao,
			}

		case LOGOUT:
			return userInitialStateReducer

		default:
			return state
	}
}

export default combineReducers({ user: userReducer })
