import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { API, inicializarScripts } from "../utils"

function Profile({}) {
	const [searchParams] = useSearchParams()
	const [usuario, setUsuario] = useState({})

	useEffect(() => {
		inicializarPerfil(searchParams.get("id"))
	}, [])

	const inicializarPerfil = async (id) => {
		const response = await API.request({
			url: "/tabelas/poupanegocios/perfil",
			method: "get",
			params: {
				id,
			},
		})

		setUsuario(response.data)
	}

	return (
		<main id="content" role="main" className="main" onLoad={() => inicializarScripts(window)}>
			{/* Content */}
			{usuario == null && (
				<div className="d-flex justify-content-center" style={{ padding: 20 }}>
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			)}
			{usuario && (
				<div className="content container-fluid">
					<div className="row justify-content-lg-center">
						<div className="col-lg-10">
							{/* Profile Cover */}
							<div className="profile-cover">
								<div className="profile-cover-img-wrapper">
									<img
										className="profile-cover-img"
										src={"./assets/img/1920x400/img1.jpg"}
										alt="Image Description"
									/>
								</div>
							</div>
							{/* End Profile Cover */}
							{/* Profile Header */}
							<div className="text-center mb-5">
								{/* Avatar */}
								<div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
									<img className="avatar-img" src={usuario.foto} alt="Image Description" />
								</div>
								{/* End Avatar */}
								<h1 className="page-header-title">{usuario.nome}</h1>
								{/* List */}
								<ul className="list-inline list-px-2">
									<li className="list-inline-item">
										<i className="bi-building me-1" />
										<span>Poupacred</span>
									</li>
									<li className="list-inline-item">
										<i className="bi-geo-alt me-1" />
										<a href="#">{usuario.escritorios}</a>
									</li>
									<li className="list-inline-item">
										<i className="bi-calendar-week me-1" />
										<span>{`Entrou em ${usuario.admissao}`}</span>
									</li>
								</ul>
								{/* End List */}
							</div>
							{/* End Profile Header */}
							<div className="row">
								<div className="col-lg-4">
									{/* Card */}
									<div className="card card-body mb-3 mb-lg-5">
										<h5>Informações do perfil</h5>
										{/* Progress */}
										<div className="d-flex justify-content-between align-items-center">
											<div className="progress flex-grow-1">
												<div
													className="progress-bar bg-primary"
													role="progressbar"
													style={{ width: "82%" }}
													aria-valuenow={82}
													aria-valuemin={0}
													aria-valuemax={100}
												/>
											</div>
											<span className="ms-4">82%</span>
										</div>
										{/* End Progress */}
									</div>
									{/* End Card */}
									{/* Sticky Block Start Point */}
									<div id="accountSidebarNav" />
									{/* Card */}
									<div
										className="js-sticky-block card mb-3 mb-lg-5"
										data-hs-sticky-block-options='{
										"parentSelector": "#accountSidebarNav",
										"breakpoint": "lg",
										"startPoint": "#accountSidebarNav",
										"endPoint": "#stickyBlockEndPoint",
										"stickyOffsetTop": 20
										}'
									>
										{/* Header */}
										<div className="card-header">
											<h4 className="card-header-title">Perfil</h4>
										</div>
										{/* End Header */}
										{/* Body */}
										<div className="card-body">
											<ul className="list-unstyled list-py-2 text-dark mb-0">
												<li className="pb-0">
													<span className="card-subtitle">Sobre</span>
												</li>
												<li>
													<i className="bi-person dropdown-item-icon" /> {usuario.id}
												</li>
												<li>
													<i className="bi-briefcase dropdown-item-icon" /> {usuario.cargos}
												</li>
												<li>
													<i className="bi-building dropdown-item-icon" /> Poupacred
												</li>
												<li className="pt-4 pb-0">
													<span className="card-subtitle">Contato</span>
												</li>
												<li>
													<i className="bi-at dropdown-item-icon" /> {usuario.email}
												</li>
												<li>
													<i className="bi-phone dropdown-item-icon" /> {usuario.tel}
												</li>
											</ul>
										</div>
										{/* End Body */}
									</div>
									{/* End Card */}
								</div>
								<div className="col-lg-8">
									<div className="d-grid gap-3 gap-lg-5">
										{/* Card */}
										<div className="card">
											{/* Header */}
											<div className="card-header card-header-content-between">
												<h4 className="card-header-title">Últimas atividades</h4>
											</div>
											{/* End Header */}
											{/* Body */}
											{false && (<div className="card-body card-body-height" style={{ height: "30rem" }}>
												{/* Step */}
												<ul className="step step-icon-xs mb-0">
													{/* Step Item */}
													<li className="step-item">
														<div className="step-content-wrapper">
															<span className="step-icon step-icon-pseudo step-icon-soft-dark" />
															<div className="step-content">
																<h5 className="step-title">
																	<a className="text-dark" href="#">
																		Project status updated
																	</a>
																</h5>
																<p className="fs-5 mb-1">
																	Marked{" "}
																	<a className="text-uppercase" href="#">
																		<i className="bi-journal-bookmark-fill" /> Fr-6
																	</a>{" "}
																	as{" "}
																	<span className="badge bg-soft-success text-success rounded-pill">
																		<span className="legend-indicator bg-success" />
																		"Completed"
																	</span>
																</p>
																<span className="text-muted small text-uppercase">Today</span>
															</div>
														</div>
													</li>
													{/* End Step Item */}
												</ul>
												{/* End Step */}
											</div>)}
											{/* End Body */}
										</div>
										{/* End Card */}
									</div>
									{/* Sticky Block End Point */}
									<div id="stickyBlockEndPoint" />
								</div>
							</div>
							{/* End Row */}
						</div>
						{/* End Col */}
					</div>
					{/* End Row */}
				</div>
			)}
			{/* End Content */}
		</main>
	)
}

export default Profile
