import { useState, useEffect, useRef } from "react";
import { API, API_CRM, ERRO, inicializarScripts, tipoArquivo } from "../utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import ContadorOnline from "../components/ContadorOnline";
import ContadorSessoes from "../components/ContadorSessoes";
import ContadorTempo from "../components/ContadorTempo";
import Paginacao from "../components/Paginacao";
import InformacoesEscobs from "../components/InformacoesEscobs";

import formatarCPF from "../utils/formatarCPF";

import io from "socket.io-client";

import "./styles/CSG.css";

const { REACT_APP_API_URL, REACT_APP_API_MS_CRM } = process.env;

const color = (x) => {
   if (x == "Reprovado" || x == "Cancelado") {
      return "danger";
   } else if (x == "Integrado") {
      return "success";
   } else if (x == "Andamento") {
      return "warning";
   } else {
      return "info";
   }
};

function CSG({ }) {
   //Refs:
   const propostasRef = useRef();

   //Configs:
   const user = useSelector((state) => state.user);
   let fPromotorDebounce = null;
   const [message, setMessage] = useState(null);
   const [socket, setSocket] = useState(null);
   const [pendentes, setPendentes] = useState(null);
   const [conectando, setConectando] = useState(true);
   const [conectado, setConectado] = useState(false);
   const [consulta, setConsulta] = useState(false);
   const [portal, setPortal] = useState(null);
   const [fullscreen, setFullscreen] = useState(false);
   const [visivel, setVisivel] = useState(true);
   const [aba, setAba] = useState(0);
   const [inputUploadCSGId, setInputUploadCSGId] = useState(null); // input file id do csg
   const [inputUpload, setInputUpload] = useState({}); //input de upload de arquivos

   //Arquivos:
   const [arquivos, setArquivos] = useState(null); // Dados de arquivos.
   const [fArquivoNome, setFArquivoNome] = useState(null); // (f)Filtro de nome de arquivo.
   const [fArquivoData, setFArquivoData] = useState(null); // (f)Filtro de data de arquivo.
   const [pArquivo, setPArquivo] = useState(1); // (p)Página atual da tabela de arquivo.
   const [toArquivo, setToArquivo] = useState(0); // (to)Total de arquivos.
   const [taArquivo, setTaArquivo] = useState(0); // (ta)Tamanho da paginação de arquivos.
   const [downloadArquivo, setDownloadArquivo] = useState(false)
   const [buscarArquivo, setBuscarArquivo] = useState(false)

   //Propostas:
   const [propostas, setPropostas] = useState([]); // Dados de propostas.
   const [fPropostaCod, setFPropostaCod] = useState(null); // (f)Filtro de código de Proposta.
   const [fPropostaData, setFPropostaData] = useState(null); // (f)Filtro de data de Proposta.
   const [pProposta, setPProposta] = useState(1); // (p)Página atual da tabela de Proposta.
   const [toProposta, setToProposta] = useState(0); // (to)Total de Propostas.
   const [taProposta, setTaProposta] = useState(0); // (ta)Tamanho da paginação de Propostas.
   const [lPropostas, setLPropostas] = useState(false); // (l)oading das Propostas.   

   const [listaStatus, setListaStatus] = useState([]);
   const [listaPromotores, setListaPromotores] = useState([]);

   const [fStatus, setFStatus] = useState(null);
   const [fPromotor, setFPromotor] = useState(null);
   const [fProposta, setFProposta] = useState(null);
   const [fInicializado, setFInicializado] = useState(false);

   const [cpf, setCpf] = useState("");
   const [dadosEscobs, setDadosEscobs] = useState({});
   const [pesquisandoEscobs, setPesquisandoEscobs] = useState(false);

   // Inicialização da página:
   useEffect(() => {
      verificarSessao();
      inicializarArquivos();
      inicializarPropostas();
      desativarTab();
      configurarTeclado();

      setCpf("");
   }, []);

   // Aplicação dos filtros ao mudar select:
   useEffect(() => {
      if (fInicializado && fProposta == null) {
         paginarProposta(1);
      } else {
         setFInicializado(true);
      }
   }, [fStatus, fPromotor]);

   // Aplicação do filtro com delay ao digitar proposta:
   useEffect(() => {
      fPromotorDebounce = setTimeout(() => {
         setFStatus(null);
         setFPromotor(null);
         paginarProposta(1);
      }, 800);
      return () => clearTimeout(fPromotorDebounce);
   }, [fProposta]);

   // Faz a conexão com socket:
   useEffect(() => {
      const newSocket = io(REACT_APP_API_URL, {
         transports: ["websocket"],
         query: "type=3",
         auth: {
            token: user.token,
         },
      });

      setSocket(newSocket);
      return () => newSocket.close();
   }, [setSocket]);

   // Faz a configuração dos eventos ao ser conectado/desconectado o socket:
   useEffect(() => {
      if (socket) {
         socket.on("desconectado", aoDesconectar);
         socket.on("texto-copiado", aoCopiar);
         socket.on("instancia-encerrada", aoEncerrar);
         socket.on("atalho", aoAtalho);
         socket.on("pendentes", aoPendentes);
         socket.on("alerta", aoAlertar);
         socket.on("novo-arquivo", aoNovoArquivo);
         socket.on("inputUpload", aoInputUpload);

         return () => {
            socket.off("desconectado", aoDesconectar);
            socket.off("texto-copiado", aoCopiar);
            socket.off("instancia-encerrada", aoEncerrar);
            socket.off("atalho", aoAtalho);
            socket.off("pendentes", aoPendentes);
            socket.off("alerta", aoAlertar);
            socket.off("novo-arquivo", aoNovoArquivo);
            socket.off("inputUpload", aoInputUpload);
         };
      }
   }, [socket]);

   // Faz a escuta das messages:
   useEffect(async () => {
      switch (message?.data) {
         case "Ctrl+X":
         case "Ctrl+C":
            return enviarComando("copiar");

         case "Ctrl+V":
            return enviarComando("colar", await navigator.clipboard.readText());
      }
   }, [message]);

   const desativarTab = () => {
      // Desativa o tab nessa página:
      window.$(document).keydown(function (e) {
         var keycode1 = e.keyCode ? e.keyCode : e.which;
         if (keycode1 == 0 || keycode1 == 9) {
            e.preventDefault();
            e.stopPropagation();
         }
      });
   };

   const configurarTeclado = () => {
      if (window.addEventListener) {
         window.addEventListener("message", (e) => setMessage(e), false);
      } else if (window.attachEvent) {
         window.attachEvent("onmessage", (e) => setMessage(e), false);
      }
   };

   const aoNovoArquivo = async (data) => {
      if (data.arquivo) {
         toast.info("Arquivo salvo com sucesso.");
         await inicializarArquivos();
         window.open(data.arquivo);
      } else {
         toast.warn("Ocorreu um erro ao salvar o arquivo");
      }
   };

   const aoInputUpload = (data) => {
      setInputUpload({});
      setInputUploadCSGId(data.id ?? null)
      window.$("#modal-upload").modal("show");
   };

   const aoAlertar = (texto) => {
      toast.info(texto);
   };

   const aoPendentes = (lista) => {
      setPendentes(lista);
      toast.warning(
         "Uma nova simulação com classificação pendente foi encontrada!"
      );
   };

   const aoCopiar = (texto) => {
      if (texto.length > 15) {
         toast.success(`Texto "${texto.substring(0, 15)}..." foi copiado.`);
      } else {
         toast.success(`Texto "${texto}" foi copiado.`);
      }

      navigator.clipboard.writeText(texto);
   };

   const aoEncerrar = (texto) => {
      toast.info(texto)
   }

   const aoAtalho = async (aux) => {
      switch (aux) {
         case "Ctrl+C":
            return enviarComando("copiar");
         case "Ctrl+V":
            return enviarComando("colar", await navigator.clipboard.readText());
      }
   };

   const aoDesconectar = (data) => {
      setConectando(false);
      setConectado(false);
      setPortal(false);
      if (data?.msg && !data.msg.startsWith("Inatividade")) {
         toast.warning(
            `Sua sessão foi desconectada. Motivo: ${data.msg || "Desconhecido"
            }`,
            {
               toastId: "csg-session",
            }
         );
      }
   };

   const inicializarArquivos = async () => {
      try {
         setArquivos(null);
         setFArquivoNome(null);
         setFArquivoData(null);
         setPArquivo(1);
         setToArquivo(0);
         setTaArquivo(0);

         const response = await API.request({
            url: "/tabelas/poupanegocios/arquivos",
            method: "get",
            params: {
               pagina: pArquivo,
               nome: fArquivoNome,
               data: fArquivoData,
            },
         });

         const { dados, total, tamanho } = response.data;
         setArquivos(dados);
         setToArquivo(total);
         setTaArquivo(tamanho);
      } catch (err) {
         ERRO(err);
      }
   };

   const inicializarPropostas = async () => {
      try {
         setPropostas(null);
         setFPropostaCod(null);
         setFPropostaData(null);
         setPProposta(1);
         setToProposta(0);
         setTaProposta(0);
         setFStatus(null);
         setFProposta(null);
         setFPromotor(null);

         const response = await API.request({
            url: "/tabelas/poupanegocios/propostas",
            method: "get",
            params: {
               pagina: pProposta,
               codigo: fPropostaCod,
               data: fPropostaData,
               filtro: {
                  status: fStatus,
                  proposta: fProposta,
                  promotor: fPromotor,
               },
            },
         });

         const { dados, total, tamanho, status, promotores } = response.data;
         setPropostas(dados);
         setToProposta(total);
         setTaProposta(tamanho);
         setListaStatus(status);
         setListaPromotores(promotores);
      } catch (err) {
         ERRO(err);
      }
   };

   const paginarProposta = async (pagina) => {
      try {
         setLPropostas(true);

         const response = await API.request({
            url: "/tabelas/poupanegocios/propostas",
            method: "get",
            params: {
               pagina: pagina,
               codigo: fPropostaCod,
               data: fPropostaData,
               filtro: {
                  status: fStatus,
                  proposta: fProposta,
                  promotor: fPromotor,
               },
            },
         });

         const { dados, total, tamanho } = response.data;
         setPProposta(pagina);
         setPropostas(dados);
         setToProposta(total);
         setTaProposta(tamanho);
         setLPropostas(false);
      } catch (err) {
         setLPropostas(false);
         ERRO(err);
      }
   };

   const paginarArquivo = async (pagina) => {
      try {
         const response = await API.request({
            url: "/tabelas/poupanegocios/arquivos",
            method: "get",
            params: {
               pagina: pagina,
               nome: fArquivoNome,
               data: fArquivoData,
            },
         })

         const { dados, total, tamanho } = response.data

         setPArquivo(pagina)
         setArquivos(dados)
         setToArquivo(total)
         setTaArquivo(tamanho)
      } catch (error) {
         console.log(error)
         toast.error("Ocorreu um erro ao paginar os arquivos")
      }
   }

   const verificarSessao = async () => {
      try {
         setConectando(true);

         const response = await API.request({
            url: "/instancias/ativa",
            method: "get",
         });

         const { portal, pendentes } = response.data;
         setPendentes(pendentes);

         if (portal) {
            setPortal(portal);
            setConectado(true);
            setConectando(false);
         } else {
            setConectado(false);
            setConectando(false);
         }

         /*
         window.addEventListener("focus", (event) => {
            if(window.$("#").focused)
            event.preventDefault()
         })
         */
      } catch (err) {
         ERRO(err);
      }
   };

   const digitar = async () => {
      try {
         setConectando(true);

         const response = await API.request({
            url: "/instancias/conectar",
            method: "get",
            timeout: 60000,
         });

         if (response.data.portal) {
            setPortal(response.data.portal);
            setConectado(true);
            setConectando(false);
         } else {
            setPortal(null);
            setConectado(false);
            setConectando(false);
            toast.info(
               response.data.mensagem ?? "Nenhuma sessão disponível foi encontrada. Tente mais tarde novamente!"
            );
         }
      } catch (err) {
         ERRO(err);

         setPortal(null);
         setConectado(false);
         setConectando(false);
      }
   };

   const consultar = async (proposta) => {
      try {
         setAba(0);
         setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
         }, 200);

         setConectando(true);

         const response = await API.request({
            url: "/instancias/conectar",
            method: "get",
            params: {
               label: "consultarProposta",
               value: proposta,
            },
            timeout: 60000,
         });

         if (response.data.portal) {
            setPortal(response.data.portal);
            setConectado(true);
            setConectando(false);
         } else {
            setPortal(null);
            setConectado(false);
            setConectando(false);
            toast.warning(
               "Nenhuma sessão disponível relacionada a proposta foi encontrada. Tente mais tarde novamente!"
            );
         }
      } catch (err) {
         ERRO(err);

         setPortal(null);
         setConectado(false);
         setConectando(false);
      }
   };

   const classificar = async (proposta, isProposta) => {
      let gravarPropostaPoupasistema = false;

      try {
         Swal.fire({
            title: `Classificar como ${isProposta ? "proposta" : "simulação"}?`,
            text: `${isProposta ? "Se optar por sim, a proposta também será gravada no Poupasistema" : "O rascunho será classificado como simulação"}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
         }).then(async (result) => {
            if (result.isConfirmed) {
               gravarPropostaPoupasistema = isProposta;

               setPendentes((pendentes) =>
                  pendentes.filter((x) => x.proposta != proposta)
               );

               await API.request({
                  url: "/instancias/classificar-simulacao",
                  method: "post",
                  data: { isProposta, proposta, gravarPropostaPoupasistema },
               });

               Swal.fire(
                  "Sucesso",
                  isProposta ? "Proposta classificada e grava no Poupasistema." : "Classificado como rascunho",
                  "success"
               )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
               Swal.fire(
                  "Aviso",
                  "Proposta não classificada.",
                  "info"
               )
            }
         })
      } catch { }
   };

   const modoConsulta = () => {
      setAba(1);
      setTimeout(() => {
         window.scrollTo({
            top: propostasRef.current.offsetTop - 90,
            behavior: "smooth",
         });
      }, 200);
   };

   const modoInicial = () => {
      setConsulta(false);
      setConectado(false);
      setConectando(false);
   };

   const alternarFullscreen = () => {
      setVisivel(false);
      setFullscreen((state) => !state);
      setTimeout(() => setVisivel(true), 200);
   };

   const enviarComando = (comando, data = null) => {
      if (socket) {
         socket.emit("comando", { comando, data });
      }

      switch (comando) {
         case "colar":
            if (data.length > 15) {
               toast.success(`Texto "${data.substring(0, 15)}..." foi colado.`);
            } else {
               toast.success(`Texto "${data}" foi colado.`);
            }
            break;
         default:
            return null;
      }
   };

   const filtrarStatus = (event) => {
      const valor = event.target.value;
      let aux = null;

      if (valor.length > 0) {
         aux = valor;
      }

      setFStatus(aux);
   };

   const filtrarPromotor = (event) => {
      const valor = event.target.value;
      let aux = null;

      if (valor.length > 0) {
         aux = valor;
      }

      setFPromotor(aux);
   };

   const filtrarProposta = (event) => {
      setFProposta(event.target.value || null);
   };

   const handlePesquisarCpfEscobs = async () => {
      if (cpf === "" || typeof cpf === "undefined") {
         toast.warn("Para realizar a pesquisa, você precisa informar um CPF");
         return;
      }

      try {
         setDadosEscobs({});
         setPesquisandoEscobs(true);

         const response = await API_CRM.request({
            url: "/prospect/buscar-cpf-escobs",
            method: "get",
            params: {
               cpf: formatarCPF(cpf),
            },
            timeout: 300000,
         });

         const { status, data } = response;

         if (typeof data.dados.dados != "undefined" && data.dados.dados.length === 0) {
            toast.info("O CPF informado não foi localizado")
         } else if (status === 201 && Object.keys(data).length > 0) {
            setDadosEscobs(data.dados)
         }
      } catch (error) {
         toast.error("Ops! Ocorreu um erro ao buscar o CPF 🤯");
         console.error(error.message);

         setCpf("");
         setDadosEscobs({});
         setPesquisandoEscobs(false);
      } finally {
         document.getElementById("txtPesquisarCpf").value = "";
         setCpf("");
         setPesquisandoEscobs(false);
      }
   };

   // const handlePesquisarCpfEscobsBkp = async () => {
   //    try {
   //       if (cpf === "" || typeof cpf === "undefined") {
   //          toast.warn(
   //             "Por favor, informe o CPF antes de realizar a pesquisa no Escobs!"
   //          );
   //          return;
   //       }

   //       setDadosEscobs({});
   //       setPesquisandoEscobs(true);

   //       const response = await API.request({
   //          url: "/instancias/conectar-escobs",
   //          method: "get",
   //          params: {
   //             cpf: formatarCPF(cpf),
   //          },
   //          timeout: 300000,
   //       });

   //       const { status, data } = response;

   //       if (status == 200 && !data.erro && Object.keys(data).length > 0) {
   //          toast.success("Dados do CPF retornados com sucesso 😁");
   //          setDadosEscobs(data);
   //       } else {
   //          toast.info(
   //             data.erro ?? "Ops! Ocorreu um erro ao pesquisar o CPF, tente novamente, por favor!"
   //          );
   //       }
   //    } catch (error) {
   //       toast.error("Ops! Ocorreu um erro ao buscar o CPF 🤯");
   //       console.log(error.message);

   //       setCpf("");
   //       setDadosEscobs({});
   //       setPesquisandoEscobs(false);
   //    } finally {
   //       document.getElementById("txtPesquisarCpf").value = "";
   //       setCpf("");
   //       setPesquisandoEscobs(false);
   //    }
   // };

   const handleLimparBuscaEscobs = async () => {
      try {
         setDadosEscobs({});
      } catch (error) {
         console.error(error);
      }
   };

   const imgPerfilNomeUsuario = (nomeUsuario) => {
      try {
         if (nomeUsuario == null || nomeUsuario == "") {
            return;
         }

         const imgPerfil = nomeUsuario.split();
         return `https://ui-avatars.com/api/?name=${encodeURIComponent(
            `${imgPerfil[0]} ${imgPerfil[1]}`
         )}&background=random`;
      } catch (error) {
         console.error(error);
      }
   };

   const fileToBase64 = (file) => {
      return new Promise((resolve) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
      });
   };

   const handleUpload = async (event) => {
      const { files } = event.target;
      const auxFile = files.length > 0 ? files[0] : null;
      if (auxFile) {
         if (auxFile.size > 1000000) {
            toast.info('Tamanho do arquivo excedido "1mb" 😲');
         } else if (
            ![
               "image/jpg",
               "image/jpeg",
               "image/png",
               "application/pdf",
            ].includes(auxFile.type)
         ) {
            toast.info(
               'Formato de arquivos aceitos. "jpg, jpeg, png, pdf!" 😲'
            );
         } else {
            setInputUpload({
               isFile: true,
               name: auxFile.name,
               type: auxFile.type,
               data: await fileToBase64(auxFile),
               files
            });
         }
      }
   };

   const handleSalvarUpload = async (event) => {
      event.preventDefault();
      if (inputUpload?.name) socket.emit("inputFileUpload", { ...inputUpload, inputId: inputUploadCSGId });
      setInputUpload({});
      setInputUploadCSGId(null);
      window.$("#arquivo").attr('src', null);
      window.$("#arquivo").val(null);
      window.$("#modal-upload").modal("hide");
   }

   const handleDownloadArquivo = async (codigo, arquivo) => {
      setDownloadArquivo(true)

      try {
         const response = await API.request({
            url: "/tabelas/poupanegocios/download-arquivo",
            method: "post",
            data: {
               codigo,
               arquivo
            }
         })

         if (response.status == 200 && response.data) {
            window.open(response.data.arquivo)
         } else {
            toast.warn("Arquivo não encontrado!")
         }

         setDownloadArquivo(false)
      } catch (error) {
         toast.error("Erro ao fazer o download do arquivo 🤯")
      }
   }

   const handleBuscarArquivo = async () => {
      setBuscarArquivo(true)

      try {
         const response = await API.request({
            url: "/tabelas/poupanegocios/arquivos",
            method: "get",
            params: {
               pagina: pArquivo,
               nome: fArquivoNome,
               data: fArquivoData,
            },
         })

         if (response.status == 200) {
            const { dados, total, tamanho } = response.data;

            setArquivos(dados);
            setToArquivo(total);
            setTaArquivo(tamanho);
         } else {
            toast.error("Erro ao retornar os registros 🤯")
         }
      } catch (error) {
         setBuscarArquivo(false)

         console.error(error)
         toast.error("Erro ao buscar o arquivo 🤯")
      }
   }

   const limparBuscarArquivo = async () => {
      try {
         document.getElementById("nome-arquivo").value = ""

         setFArquivoNome(null)
         setBuscarArquivo(false)

         await inicializarArquivos()
      } catch (error) {
         console.error(error)
         toast.error("Erro ao limpar a busca dos arquivos 🤯")
      }
   }

   function handleMouseOver () {
      // Get the current page scroll position
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

      // if any scroll is attempted, set this to the previous value
      window.onscroll = function() {
         window.scrollTo(scrollLeft, scrollTop);
      };
   }

   function handleMouseLeave () {
      window.onscroll = function() {};
   }

   return (
      <div>
         <main
            id="content"
            role="main"
            className="main"
            onLoad={() => inicializarScripts(window)}
         >
            {/* Content */}
            <div>
               <div
                  className="content container-fluid"
                  style={{ height: "48vh", minHeight: "26rem" }}
               >
                  {/* Page Header */}
                  <div className="page-header page-header-light" style={{ marginTop: "3.75rem" }}>
                     <div
                        style={{
                           width: "100%",
                           textAlign: "center",
                           height: "6vh",
                        }}
                     >
                        <img
                           src="./assets/svg/logos/logo.png"
                           alt="Logo"
                           style={{
                              width: "20rem",
                              maxWidth: "20rem",
                              marginTop: "-0.5rem",
                           }}
                        />
                     </div>
                     {/* End Row */}
                     {/* Nav Scroller */}
                     <div className="js-nav-scroller hs-nav-scroller-horizontal">
                        <span
                           className="hs-nav-scroller-arrow-prev hs-nav-scroller-arrow-dark-prev"
                           style={{ display: "none" }}
                        >
                           <a className="hs-nav-scroller-arrow-link" href="#">
                              <i className="bi-chevron-left" />
                           </a>
                        </span>
                        <span
                           className="hs-nav-scroller-arrow-next hs-nav-scroller-arrow-dark-next"
                           style={{ display: "none" }}
                        >
                           <a className="hs-nav-scroller-arrow-link" href="#">
                              <i className="bi-chevron-right" />
                           </a>
                        </span>
                        {/* Nav */}
                        <ul
                           className="nav nav-light nav-tabs nav-tabs-light page-header-tabs"
                           id="pageHeaderTab"
                           role="tablist"
                        >
                           <li className="nav-item">
                              <button
                                 className={`nav-link ${aba === 0 ? "active" : ""
                                    }`}
                                 type="button"
                                 onClick={() => setAba(0)}
                              >
                                 Operação
                              </button>
                           </li>
                           <li className="nav-item">
                              <button
                                 className={`nav-link ${aba === 1 ? "active" : ""
                                    }`}
                                 type="button"
                                 onClick={() => setAba(1)}
                              >
                                 Propostas
                              </button>
                           </li>

                           <li className="nav-item">
                              <button
                                 className={`nav-link ${aba === 2 ? "active" : ""}`}
                                 type="button"
                                 onClick={() => setAba(2)}
                              >
                                 Arquivos
                              </button>
                           </li>
                           <li className="nav-item">
                              <button
                                 className={`nav-link ${aba === 3 ? "active" : ""
                                    }`}
                                 type="button"
                                 onClick={() => setAba(3)}
                              >
                                 Relatórios
                              </button>
                           </li>
                        </ul>
                        {/* End Nav */}
                     </div>
                     {/* End Nav Scroller */}
                  </div>
                  {/* End Page Header */}
               </div>
            </div>
            {/* End Content */}
            {/* Content */}
            <div
               className="content container-fluid"
               style={{ marginTop: "-17rem" }}
            >
               {aba === 0 && (
                  <div className="mt-3">
                     <div className="row">
                        {pendentes &&
                           pendentes.map((x, i) => (
                              <div key={x.id} className="col-xl-3 col-6 mb-5">
                                 <div className="card card-sm">
                                    <div className="card-body">
                                       <div className="input-group input-group-sm input-group-merge">
                                          <input
                                             id={`card-proposta${i}`}
                                             type="text"
                                             className="form-control inout-sm"
                                             readOnly
                                             defaultValue={x.proposta}
                                          />
                                          <a class="js-clipboard input-group-text bg-white" href="javascript:;" data-toggle="tooltip" title="Copiar número da proposta"
                                             data-hs-clipboard-options={`
                                                {
                                                   "type": "tooltip",
                                                   "successText": "Copiado",
                                                   "contentTarget": "#card-proposta${i}",
                                                   "classChangeTarget": "#icone${i}",
                                                   "defaultClass": "bi-clipboard",
                                                   "successClass": "bi-check"
                                                }
                                             `}>
                                             <i id={`icone${i}`} className="bi-clipboard"></i>
                                          </a>
                                          {/* <a
                                             className="js-clipboard input-group-append input-group-text"
                                             href="javascript:;"
                                             data-bs-toggle="tooltip"
                                             title={""}
                                             data-hs-clipboard-options={`{
                                                "type": "tooltip",
                                                "successText": "Copiado!",
                                                "contentTarget": "#code${i}",
                                                "classChangeTarget": "#icon${i}",
                                                "defaultClass": "bi-clipboard",
                                                "successClass": "bi-check"
                                             }`}
                                             data-bs-original-title="Copiar número da proposta"
                                          >
                                             <i
                                                id={`icon${i}`}
                                                className="bi-clipboard"
                                             />
                                          </a> */}
                                       </div>
                                       <p
                                          className="card-text mt-2"
                                          style={{ textAlign: "justify" }}
                                       >
                                          <b>Classifique:</b> A simulação do(a)
                                          cliente{" "}
                                          <b className="csg-text-highlight">
                                             {x.nome} – {x.cpf}
                                          </b>
                                          , feita às <b>{x.hora}</b> do dia{" "}
                                          <b>{x.data}</b>, usando convênio{" "}
                                          <b>{x.convenio}</b> e regra{" "}
                                          <b>{x.regra}</b>, avançará e se
                                          tornará uma proposta?
                                       </p>
                                       <div className="row">
                                          <div className="col text-center">
                                             <a
                                                onClick={() =>
                                                   classificar(x.proposta, true)
                                                }
                                                className="card-link text-success blue-tooltip"
                                                href="javascript:;"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Classificar a simulação  como uma 'Proposta'. Use essa opção caso a negociação TENHA avançado a ponto de gerar uma proposta."
                                             >
                                                Sim
                                             </a>
                                          </div>
                                          <div className="col text-center">
                                             <a
                                                onClick={() =>
                                                   classificar(
                                                      x.proposta,
                                                      false
                                                   )
                                                }
                                                className="card-link text-danger"
                                                href="javascript:;"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Classificar como apenas uma 'Simulação'. Use essa opção caso a negociação NÃO TENHA avançado a ponto de gerar uma proposta."
                                             >
                                                Não
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           ))}
                     </div>
                     <div
                        className="card mb-3 mb-lg-5 mt-3"
                        style={{ overflow: "hidden" }}
                     >
                        {/* Header */}
                        <div className="card-header card-header-content-between">
                           <h4 className="card-header-title">Operação</h4>
                           {/* Dropdown */}
                           <div className="dropdown">
                              <button
                                 type="button"
                                 className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                 id="reportsOverviewDropdown2"
                                 data-bs-toggle="dropdown"
                                 aria-expanded="false"
                              >
                                 <i className="bi-three-dots-vertical" />
                              </button>
                              <div
                                 className="dropdown-menu dropdown-menu-end mt-1"
                                 aria-labelledby="reportsOverviewDropdown2"
                                 style={{}}
                              >
                                 <span className="dropdown-header">
                                    Settings
                                 </span>
                                 <a className="dropdown-item" href="#">
                                    <i className="bi-share-fill dropdown-item-icon" />{" "}
                                    Share reports
                                 </a>
                                 <a className="dropdown-item" href="#">
                                    <i className="bi-download dropdown-item-icon" />{" "}
                                    Download
                                 </a>
                                 <a className="dropdown-item" href="#">
                                    <i className="bi-alt dropdown-item-icon" />{" "}
                                    Connect other apps
                                 </a>
                                 <div className="dropdown-divider" />
                                 <span className="dropdown-header">
                                    Feedback
                                 </span>
                                 <a className="dropdown-item" href="#">
                                    <i className="bi-chat-left-dots dropdown-item-icon" />{" "}
                                    Report
                                 </a>
                              </div>
                           </div>
                           {/* End Dropdown */}
                        </div>
                        {/* End Header */}
                        {/* Body */}
                        <div className="card-body">
                           {conectado && (
                              <div className="row col-sm-divider">
                                 <div className="col-sm-3">
                                    {/* Stats */}
                                    <div className="d-lg-flex align-items-md-center">
                                       <div className="flex-shrink-0">
                                          <i className="bi-person fs-1 text-primary" />
                                       </div>
                                       <div className="flex-grow-1 ms-lg-3">
                                          <span className="d-block fs-6">
                                             Usuários Online
                                          </span>
                                          <div className="d-flex align-items-center">
                                             <ContadorOnline socket={socket} />
                                          </div>
                                       </div>
                                    </div>
                                    {/* End Stats */}
                                 </div>
                                 <div className="col-sm-3">
                                    {/* Stats */}
                                    <div className="d-lg-flex align-items-md-center">
                                       <div className="flex-shrink-0">
                                          <i className="bi-clock-history fs-1 text-primary" />
                                       </div>
                                       <div className="flex-grow-1 ms-lg-3">
                                          <span className="d-block fs-6">
                                             Sessões Disponíveis
                                          </span>
                                          <div className="d--flex align-items-center">
                                             <ContadorSessoes socket={socket} />
                                          </div>
                                       </div>
                                    </div>
                                    {/* End Stats */}
                                 </div>
                                 <div className="col-sm-3">
                                    {/* Stats */}
                                    <div className="d-lg-flex align-items-md-center">
                                       <div className="flex-shrink-0">
                                          <i className="bi-files-alt fs-1 text-primary" />
                                       </div>
                                       <div className="flex-grow-1 ms-lg-3">
                                          <span className="d-block fs-6">
                                             Status CSG
                                          </span>
                                          <div className="d--flex align-items-center">
                                             <h3 className="mb-0">Normal</h3>
                                          </div>
                                       </div>
                                    </div>
                                    {/* End Stats */}
                                 </div>
                                 <div className="col-sm-3">
                                    {/* Stats */}
                                    <div className="d-lg-flex align-items-md-center">
                                       <div className="flex-shrink-0">
                                          <i className="bi-clock-history fs-1 text-primary" />
                                       </div>
                                       <div className="flex-grow-1 ms-lg-3">
                                          <span className="d-block fs-6">
                                             Tempo Restante
                                          </span>
                                          <div className="d--flex align-items-center">
                                             <ContadorTempo socket={socket} />
                                          </div>
                                       </div>
                                    </div>
                                    {/* End Stats */}
                                 </div>
                              </div>
                           )}
                           {pendentes && pendentes.length >= 8 && (
                              <div className="csg-text-container">
                                 <p className="csg-text">
                                    <b className="csg-text-red">
                                       Atenção, classifique as simulações acima
                                       para continuar.
                                    </b>{" "}
                                    Assim que finalizar a classificação, você
                                    poderá acessar o sistema novamente. Essa
                                    classificação é importante pois com ela,
                                    conseguimos acompanhar as propostas e fazer
                                    todo trabalho administrativo que envolve
                                    nossa operação.
                                 </p>
                              </div>
                           )}
                           {!conectado &&
                              !conectando &&
                              !consulta &&
                              pendentes.length < 8 && (
                                 <div className="csg-text-container">
                                    <p className="csg-text">
                                       <b className="text-poupacred">
                                          E aí, vai digitar ou consultar uma
                                          proposta?
                                       </b>{" "}
                                       Encontraremos uma sessão de acordo com
                                       sua necessidade. Caso você precise{" "}
                                       <b>digitar ou simular uma proposta</b>,
                                       clique no botão "Digitar". Se você
                                       precisa <b>encontrar uma proposta</b> já
                                       digitada, use o botão "Consultar".
                                       Lembrando que no modo consulta, não é
                                       possível fazer uma digitação. Se após
                                       consultar, você queira digitar outra
                                       proposta, clique no botão{" "}
                                       <i className="bi-three-dots-vertical"></i>{" "}
                                       localizado acima e escolha a opção{" "}
                                       <i>Alternar para modo Digitação</i>.
                                    </p>
                                    <div className="csg-buttons-container">
                                       <button
                                          onClick={digitar}
                                          type="button"
                                          className="btn btn-poupacred csg-buttons"
                                          style={{ marginRight: "25px" }}
                                       >
                                          <span className="h1 text-white">
                                             🤑 Digitar
                                          </span>
                                       </button>
                                       <button
                                          onClick={modoConsulta}
                                          type="button"
                                          className="btn btn-poupacred csg-buttons"
                                       >
                                          <span className="h1 text-white">
                                             🧐 Consultar
                                          </span>
                                          <br />
                                          <span className="h6 text-white">
                                             ou dar continuedade a uma proposta
                                          </span>
                                       </button>
                                    </div>
                                 </div>
                              )}
                           {!conectado && conectando && (
                              <div className="csg-loading-container text-center">
                                 <div className="d-flex justify-content-center">
                                    <div
                                       className="spinner-border"
                                       role="status"
                                    >
                                       <span className="visually-hidden">
                                          Loading...
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           )}
                        </div>
                        {conectado && !conectando && portal && (
                           <div
                              className={`csg-container${fullscreen ? " fullscreen" : ""
                                 }`}
                           >
                              {visivel && (
                                 <iframe 
                                 className="csg" 
                                 onMouseOver={handleMouseOver} 
                                 onMouseLeave={handleMouseLeave} 
                                 src={portal}></iframe>
                              )}
                              <div
                                 style={{
                                    position: "absolute",
                                    bottom: fullscreen ? 30 : 20,
                                    right: 20,
                                 }}
                              >
                                 <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Button group with nested dropdown"
                                 >
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       onClick={alternarFullscreen}
                                       className="btn btn-lg btn-primary btn-icon"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title={
                                          fullscreen
                                             ? "Minimizar a tela"
                                             : "Maximizar a tela"
                                       }
                                    >
                                       <i
                                          className={`"bi bi-fullscreen${fullscreen ? "-exit" : ""
                                             }`}
                                       ></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Voltar a página inicial"
                                       onClick={() => enviarComando("home")}
                                    >
                                       <i className="bi bi-house"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Encerrar"
                                       onClick={() => enviarComando("encerrar")}
                                    >
                                       <i className="bi bi-door-closed"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Voltar a página anterior"
                                       onClick={() => enviarComando("voltar")}
                                    >
                                       <i className="bi bi-arrow-90deg-left"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Atualizar a página"
                                       onClick={() =>
                                          enviarComando("atualizar")
                                       }
                                    >
                                       <i className="bi bi-arrow-clockwise"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Copiar seleção"
                                       onClick={() => enviarComando("copiar")}
                                    >
                                       <i className="bi bi-clipboard"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Colar texto"
                                       onClick={async () =>
                                          enviarComando(
                                             "colar",
                                             await navigator.clipboard.readText()
                                          )
                                       }
                                    >
                                       <i className="bi bi-clipboard-check"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Aumentar zoom"
                                       onClick={() =>
                                          enviarComando("aumentar-zoom")
                                       }
                                    >
                                       <i className="bi bi-zoom-in"></i>
                                    </button>
                                    <button
                                       tabIndex={-1}
                                       type="button"
                                       className="btn btn-lg btn-primary"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       title="Diminuir zoom"
                                       onClick={() =>
                                          enviarComando("diminuir-zoom")
                                       }
                                    >
                                       <i className="bi bi-zoom-out"></i>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               )}
               {aba === 1 && (
                  <div
                     ref={propostasRef}
                     className="card mb-3 mb-lg-5 mt-3"
                     style={{ overflow: "hidden" }}
                  >
                     {/* Header */}
                     <div className="card-header">
                        <div className="row justify-content-between align-items-center flex-grow-1">
                           <div className="col-md">
                              <div className="d-flex justify-content-between align-items-center">
                                 <h4 className="card-header-title">
                                    Propostas
                                 </h4>
                              </div>
                           </div>
                           {/* End Col */}
                           <div className="col-auto">
                              {/* Filter */}
                              <div className="row align-items-sm-center">
                                 <div className="col-sm-auto">
                                    <div className="row align-items-center gx-0">
                                       <div className="col">
                                          <span className="text-secondary me-2">
                                             Status:
                                          </span>
                                       </div>
                                       {/* End Col */}
                                       <div className="col-auto">
                                          {/* Select */}
                                          <select
                                             className="form-select form-select-sm form-select-borderless"
                                             tabIndex={-1}
                                             onChange={filtrarStatus}
                                             value={fStatus || ""}
                                          >
                                             <option value="">Todos</option>
                                             {listaStatus.map((x) => (
                                                <option key={x} value={x}>
                                                   {x}
                                                </option>
                                             ))}
                                          </select>
                                          {/* End Select */}
                                       </div>
                                       {/* End Col */}
                                    </div>
                                    {/* End Row */}
                                 </div>
                                 <div className="col-sm-auto">
                                    <div className="row align-items-center gx-0">
                                       <div className="col">
                                          <span className="text-secondary me-2">
                                             Promotores:
                                          </span>
                                       </div>
                                       {/* End Col */}
                                       <div className="col-auto">
                                          {/* Select */}
                                          <select
                                             className="form-select form-select-sm form-select-borderless"
                                             tabIndex={-1}
                                             onChange={filtrarPromotor}
                                             value={fPromotor || ""}
                                          >
                                             <option value="">Todos</option>
                                             {listaPromotores.map((x) => (
                                                <option key={x.id} value={x.id}>
                                                   {x.nome}
                                                </option>
                                             ))}
                                          </select>
                                          {/* End Select */}
                                       </div>
                                       {/* End Col */}
                                    </div>
                                    {/* End Row */}
                                 </div>
                                 {/* End Col */}
                                 <div className="col-md">
                                    {/* Search */}
                                    <div className="input-group input-group-merge">
                                       <div className="input-group-prepend input-group-text">
                                          <i className="bi-search" />
                                       </div>
                                       <input
                                          id="datatableSearch"
                                          type="search"
                                          className="form-control"
                                          placeholder="Proposta"
                                          aria-label="Proposta"
                                          value={fProposta || ""}
                                          onChange={filtrarProposta}
                                       />
                                    </div>
                                    {/* End Search */}
                                 </div>
                                 {/* End Col */}
                              </div>
                              {/* End Filter */}
                           </div>
                           {/* End Col */}
                        </div>
                        {/* End Row */}
                     </div>
                     {/* End Header */}
                     {/* Body */}
                     <div className="card-body" style={{ padding: 0 }}>
                        {propostas == null && (
                           <div className="d-flex justify-content-center mt-3 mb-3">
                              <span>...</span>
                           </div>
                        )}
                        {propostas && propostas.length == 0 && (
                           <p className="text-center mt-3 mb-3">
                              Nenhuma simulação/proposta foi encontrada!
                           </p>
                        )}
                        {propostas && propostas.length > 0 && (
                           <div className="table-responsive datatable-custom">
                              <table
                                 className="js-datatable table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                 data-hs-datatables-options='{
										"order": []
										}'
                              >
                                 <thead className="thead-light">
                                    <tr>
                                       <th>Data</th>
                                       <th>Proposta</th>
                                       <th>Convênio</th>
                                       <th>Cliente</th>
                                       <th>Status</th>
                                       <th>Sistema</th>
                                       <th>Promotor</th>
                                       <th>Ações</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {propostas.map((x) => (
                                       <tr key={x.proposta}>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                {x.data}
                                             </span>
                                             <span className="d-block fs-5">
                                                Criação
                                             </span>
                                          </td>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                {x.proposta}
                                             </span>
                                             <span className="d-block fs-5">
                                                {x.tipo}
                                             </span>
                                          </td>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                {x.convenio}
                                             </span>
                                             <span className="d-block fs-5">
                                                {x.regra}
                                             </span>
                                          </td>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                {x.nome}
                                             </span>
                                             <span className="d-block fs-5">
                                                {x.cpf}
                                             </span>
                                          </td>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                <span
                                                   className={`legend-indicator bg-${color(
                                                      x.status
                                                   )}`}
                                                />
                                                {x.status}
                                             </span>
                                             <span className="d-block fs-5">
                                                {x.proxima_atividade}
                                             </span>
                                          </td>
                                          <td>
                                             <span className="d-block h5 mb-0">
                                                <span
                                                   className={`legend-indicator bg-${x.versao == "2.0.0"
                                                      ? "success"
                                                      : "danger"
                                                      }`}
                                                />
                                                {x.versao == "2.0.0"
                                                   ? "Novo"
                                                   : "Antigo"}
                                             </span>
                                             <span className="d-block fs-5">
                                                {x.versao == "2.0.0"
                                                   ? "Web"
                                                   : "Software"}
                                             </span>
                                          </td>
                                          <td>
                                             <a
                                                className="d-flex align-items-center"
                                                href={`../perfil?id=${x.usuario_id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                             >
                                                <div className="avatar avatar-circle">
                                                   <img
                                                      className="avatar-img"
                                                      src={imgPerfilNomeUsuario(
                                                         x.usuario_nome
                                                      )}
                                                      alt="Image Description"
                                                   />
                                                </div>
                                                <div className="ms-3">
                                                   <span className="d-block h5 text-inherit mb-0">
                                                      {x.usuario_nome}
                                                   </span>
                                                   <span className="d-block fs-5 text-body">
                                                      {x.escritorio}
                                                   </span>
                                                </div>
                                             </a>
                                          </td>
                                          <td>
                                             <button
                                                onClick={() =>
                                                   consultar(x.proposta)
                                                }
                                                type="button"
                                                className="btn btn-primary btn-icon btn-sm"
                                             >
                                                <i className="bi-search"></i>
                                             </button>
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        )}
                        <div style={{ paddingLeft: 20, paddingBottom: 10 }}>
                           {propostas && propostas.length > 0 && (
                              <Paginacao
                                 pagina={pProposta}
                                 tamanho={taProposta}
                                 total={toProposta}
                                 onChange={paginarProposta}
                              />
                           )}
                        </div>
                        {lPropostas && (
                           <div className="csg-tabela-loader">
                              <div className="spinner-border" role="status">
                                 <span className="visually-hidden">
                                    Loading...
                                 </span>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               )}
               {aba === 3 && (
                  <div
                     className="card mb-3 mb-lg-5 mt-3"
                     style={{ overflow: "hidden" }}
                  >
                     {/* Header */}
                     <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">Relatórios</h4>
                     </div>
                     {/* End Header */}
                     {/* Body */}
                     <div className="card-body">
                        <p className="text-center mt-3 mb-3">
                           Nenhum relatório disponível.
                        </p>
                     </div>
                  </div>
               )}
               {/* End Card */}

               {/* Escobs */}
               {!fullscreen && aba === 0 && (
                  <section className="card mb-3 mb-lg-5 mt-3">
                     <div className="card-header card-header-content-sm-between">
                        <h4 className="card-header-title mb-2 mb-sm-0">
                           Escobs
                        </h4>
                        <div
                           className="row col-12 col-md-10 col-lg-8"
                           style={{ justifyContent: "flex-end" }}
                        >
                           <div className="col-6 col-md-4 col-lg-4">
                              <div className="input-group input-group-merge">
                                 <div className="input-group-prepend input-group-text">
                                    <i className="bi-search"></i>
                                 </div>
                                 <input
                                    type="search"
                                    name="txtPesquisarCpf"
                                    id="txtPesquisarCpf"
                                    className="form-control"
                                    placeholder="CPF"
                                    aria-label="CPF"
                                    onChange={(el) => setCpf(el.target.value)}
                                 />
                              </div>
                           </div>
                           <div className="col-6 col-md-2 col-lg-2">
                              {pesquisandoEscobs ? (
                                 <button
                                    className="btn btn-poupacred"
                                    type="button"
                                    disabled
                                 >
                                    <span
                                       className="spinner-border spinner-border-sm"
                                       role="status"
                                       aria-hidden="true"
                                    />
                                    {" Buscando..."}
                                 </button>
                              ) : (
                                 <button
                                    type="button"
                                    className="btn btn-poupacred w-100"
                                    onClick={handlePesquisarCpfEscobs}
                                 >
                                    <i className="bi-search" />
                                    &nbsp;
                                    <span className="d-none d-sm-inline-block me-1">
                                       Buscar
                                    </span>
                                 </button>
                              )}
                           </div>
                           {dadosEscobs &&
                              Object.keys(dadosEscobs).length > 0 ? (
                              <div className="col-6 col-md-2 col-lg-2">
                                 <button
                                    type="button"
                                    className="btn btn-soft-secondary w-100"
                                    onClick={handleLimparBuscaEscobs}
                                 >
                                    <i className="bi-eraser" />
                                    &nbsp;
                                    <span className="d-none d-sm-inline-block me-1">
                                       Limpar
                                    </span>
                                 </button>
                              </div>
                           ) : (
                              <></>
                           )}
                        </div>
                     </div>
                     <div className="card-body container-escobs-info">
                        {dadosEscobs && Object.keys(dadosEscobs).length > 0 ? (
                           <InformacoesEscobs dados={dadosEscobs} />
                        ) : pesquisandoEscobs ? (
                           <p className="text-center mt-3 mb-3">
                              Aguarde uns instantes, por favor! Estamos
                              conferindo os dados referente ao CPF informado
                           </p>
                        ) : (
                           <p className="text-center mt-3 mb-3">
                              Informe um CPF válido e obtenha suas informações
                              no Escobs
                           </p>
                        )}
                     </div>
                  </section>
               )}
               {/* End Escobs */}

               {/* Files */}
               {!fullscreen && [0, 2].includes(aba) && (
                  <div
                     className="card mb-3 mb-lg-5 mt-3"
                     style={{ overflow: "hidden" }}
                  >
                     {/* Header */}
                     <div className="card-header card-header-content-sm-between">
                        <h4 className="card-header-title mb-2 mb-sm-0">
                           Arquivos
                        </h4>
                        <div
                           className="row col-12 col-md-10 col-lg-8"
                           style={{ justifyContent: "flex-end" }}
                        >
                           <div className="col-6 col-md-4 col-lg-4">
                              <div className="input-group input-group-merge">
                                 <div className="input-group-prepend input-group-text">
                                    <i className="bi-search"></i>
                                 </div>
                                 <input
                                    type="search"
                                    name="nome-arquivo"
                                    id="nome-arquivo"
                                    className="form-control"
                                    placeholder="Nome do arquivo"
                                    aria-label="Nome do arquivo"
                                    onChange={(el) => setFArquivoNome(el.target.value)}
                                 />
                              </div>
                           </div>
                           <div className="col-6 col-md-2 col-lg-2">
                              <button
                                 type="button"
                                 className="btn btn-poupacred w-100"
                                 onClick={handleBuscarArquivo}
                              >
                                 <i className="bi-search" />
                                 &nbsp;
                                 <span className="d-none d-sm-inline-block me-1">
                                    Buscar
                                 </span>
                              </button>
                           </div>
                           {buscarArquivo ? (
                              <div className="col-6 col-md-2 col-lg-2">
                                 <button
                                    type="button"
                                    className="btn btn-soft-secondary w-100"
                                    onClick={limparBuscarArquivo}
                                 >
                                    <i className="bi-eraser" />
                                    &nbsp;
                                    <span className="d-none d-sm-inline-block me-1">
                                       Limpar
                                    </span>
                                 </button>
                              </div>
                           ) : ""}
                        </div>
                     </div>
                     {/* Body */}
                     <div className="card-body">
                        {arquivos == null && (
                           <div className="d-flex justify-content-center mt-3 mb-3">
                              <div className="spinner-border" role="status">
                                 <span className="visually-hidden">
                                    Carregando...
                                 </span>
                              </div>
                           </div>
                        )}
                        {arquivos && arquivos.length == 0 && (
                           <p className="text-center mt-3 mb-3">
                              Você não tem nenhum arquivo salvo!{" "}
                              <b>Contratos</b> e quaisquer arquivos oriundos do
                              CSG serão mostrados aqui.
                           </p>
                        )}
                        {arquivos && arquivos.length > 0 && (
                           <ul className="list-group mb-3">
                              {/* Item */}
                              {arquivos.map((x) => (
                                 <li key={x.id} className="list-group-item">
                                    <div className="row align-items-center">
                                       <div className="col-auto">
                                          <img
                                             className="avatar avatar-xs avatar-4x3"
                                             src={tipoArquivo(x.tipo)}
                                             alt="Image Description"
                                          />
                                       </div>
                                       <div className="col">
                                          <span className="mb-0">
                                             <span className="h5 text-dark">
                                                {x.arquivo}
                                             </span>{" "}
                                             <span className="small text-body">{`salvo por ${x.autor}`}</span>
                                          </span>
                                          <ul className="list-inline list-separator small text-body">
                                             <li className="list-inline-item">
                                                {`Salvo ${x.data} • ${x.tamanho} • `}
                                                <span style={{ color: "red" }}>
                                                   {`Exclusão automática em mais ${x.exclusao}.`}
                                                </span>
                                             </li>
                                          </ul>
                                       </div>
                                       {/* End Col */}
                                       <div className="col-auto">
                                          <button
                                             type="button"
                                             className="btn btn-poupacred btn-sm"
                                             onClick={() => handleDownloadArquivo(x.id, x.arquivo)}
                                             disabled={downloadArquivo ? true : false}
                                          >
                                             <i className="bi-box-arrow-down" />
                                             &nbsp;
                                             <span className="d-none d-sm-inline-block me-1">
                                                Download
                                             </span>
                                          </button>
                                       </div>
                                    </div>
                                    {/* End Row */}
                                 </li>
                              ))}
                              {/* End Item */}
                           </ul>
                        )}
                        {arquivos && arquivos.length > 0 && (
                           <Paginacao
                              pagina={pArquivo}
                              tamanho={taArquivo}
                              total={toArquivo}
                              onChange={paginarArquivo}
                           />
                        )}
                     </div>
                  </div>
               )}
               {/* End Files */}
               {/* Card */}
               {false && !fullscreen && (
                  <div className="card">
                     {/* Header */}
                     <div className="card-header card-header-content-between">
                        <h4
                           id="white"
                           className="card-header-title hs-docs-heading"
                        >
                           Suporte{" "}
                           <a
                              className="anchorjs-link"
                              href="#white"
                              aria-label="Anchor"
                              data-anchorjs-icon="#"
                           />
                        </h4>
                     </div>
                     {/* End Header */}
                     <div className="row">
                        <div className="col-lg-12">
                           {/* Body */}
                           <div
                              className="card-body card-body-height"
                              style={{ height: "25rem" }}
                           >
                              {/* List Comment */}
                              <ul className="list-comment">
                                 {/* Review */}
                                 <li className="list-comment-item">
                                    {/* Media */}
                                    <div className="d-flex mb-2">
                                       <div className="flex-shrink-0">
                                          {/* Avatar */}
                                          <div className="avatar avatar-sm avatar-circle mb-2">
                                             <img
                                                className="avatar-img"
                                                src="./assets/img/160x160/img10.jpg"
                                                alt="Image Description"
                                             />
                                          </div>
                                          {/* End Avatar */}
                                       </div>
                                       <div className="flex-grow-1 ms-2">
                                          <div className="row">
                                             <div className="col">
                                                <h5 className="mb-0">
                                                   Amanda Harvey{" "}
                                                   <span className="badge bg-primary">
                                                      Promotor
                                                   </span>
                                                </h5>
                                                <ul className="list-inline fs-6">
                                                   <li className="list-inline-item">
                                                      1 dia atrás
                                                   </li>
                                                </ul>
                                             </div>
                                             {/* End Col */}
                                             <div className="col-auto">
                                                {/* Dropdown */}
                                                <div className="dropdown">
                                                   <button
                                                      type="button"
                                                      className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                                      id="reviewMoreDropdown1"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                   >
                                                      <i className="bi-three-dots-vertical" />
                                                   </button>
                                                   <div
                                                      className="dropdown-menu dropdown-menu-end mt-1"
                                                      aria-labelledby="reviewMoreDropdown1"
                                                   >
                                                      <a
                                                         className="dropdown-item"
                                                         href="#"
                                                      >
                                                         <i className="bi-flag dropdown-item-icon" />{" "}
                                                         Marcar como
                                                         Inapropriado
                                                      </a>
                                                      <a
                                                         className="dropdown-item"
                                                         href="#"
                                                      >
                                                         <i className="bi-exclamation-diamond dropdown-item-icon" />{" "}
                                                         Marcar como Spam
                                                      </a>
                                                   </div>
                                                </div>
                                                {/* End Dropdown */}
                                             </div>
                                             {/* End Col */}
                                          </div>
                                          {/* End Row */}
                                       </div>
                                    </div>
                                    {/* End Media */}
                                    <h5 className="mb-1">
                                       Lorem ipsum dolor sit amet, consectetur
                                       adipiscing elit.
                                    </h5>
                                    <p>
                                       Lorem ipsum dolor sit amet, consectetur
                                       adipiscing elit. Suspendisse convallis
                                       risus eu urna pharetra posuere sit amet
                                       sed felis. Suspendisse fermentum bibendum
                                       orci, vitae rhoncus ex scelerisque ut.
                                       Mauris in bibendum est. Donec laoreet non
                                       elit eget hendrerit. Maecenas ac faucibus
                                       augue. Aenean in turpis sem. Nulla a
                                       interdum arcu. Aenean sed volutpat
                                       sapien.
                                    </p>
                                    <a href="#">
                                       <i className="bi-reply" /> Responder
                                    </a>
                                 </li>
                                 {/* End Review */}
                                 {/* Review */}
                                 <li className="list-comment-item">
                                    {/* Media */}
                                    <div className="d-flex mb-2">
                                       <div className="flex-shrink-0">
                                          {/* Avatar */}
                                          <div className="avatar avatar-sm avatar-circle mb-2">
                                             <img
                                                className="avatar-img"
                                                src="./assets/img/160x160/img3.jpg"
                                                alt="Image Description"
                                             />
                                          </div>
                                          {/* End Avatar */}
                                       </div>
                                       <div className="flex-grow-1 ms-2">
                                          <div className="row">
                                             <div className="col">
                                                <h5 className="mb-0">
                                                   David Harrison{" "}
                                                   <span className="badge bg-primary">
                                                      Promotor
                                                   </span>
                                                </h5>
                                                <ul className="list-inline fs-6">
                                                   <li className="list-inline-item">
                                                      3 semanas atrás
                                                   </li>
                                                </ul>
                                             </div>
                                             {/* End Col */}
                                             <div className="col-auto">
                                                {/* Dropdown */}
                                                <div className="dropdown">
                                                   <button
                                                      type="button"
                                                      className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                                      id="reviewMoreDropdown2"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                   >
                                                      <i className="bi-three-dots-vertical" />
                                                   </button>
                                                   <div
                                                      className="dropdown-menu dropdown-menu-end mt-1"
                                                      aria-labelledby="reviewMoreDropdown2"
                                                   >
                                                      <a
                                                         className="dropdown-item"
                                                         href="#"
                                                      >
                                                         <i className="bi-flag dropdown-item-icon" />{" "}
                                                         Marcar como
                                                         Inapropriado
                                                      </a>
                                                      <a
                                                         className="dropdown-item"
                                                         href="#"
                                                      >
                                                         <i className="bi-exclamation-diamond dropdown-item-icon" />{" "}
                                                         Marcar como Spam
                                                      </a>
                                                   </div>
                                                </div>
                                                {/* End Dropdown */}
                                             </div>
                                             {/* End Col */}
                                          </div>
                                          {/* End Row */}
                                       </div>
                                    </div>
                                    {/* End Media */}
                                    <h5 className="mb-1">
                                       Lorem ipsum dolor sit amet, consectetur
                                       adipiscing elit.
                                    </h5>
                                    <p>
                                       Lorem ipsum dolor sit amet, consectetur
                                       adipiscing elit.
                                    </p>
                                    <blockquote className="blockquote blockquote-sm mt-4">
                                       {/* Media */}
                                       <div className="d-flex">
                                          <div className="flex-shrink-0">
                                             <div className="avatar avatar-sm avatar-circle mb-2">
                                                <img
                                                   className="avatar-img"
                                                   src="./assets/svg/brands/htmlstream.svg"
                                                   alt="Image Description"
                                                />
                                             </div>
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                             <h5 className="mb-0">
                                                Carla Vanessa{" "}
                                                <span className="badge bg-danger">
                                                   Suporte
                                                </span>
                                             </h5>
                                             <ul className="list-inline fs-6">
                                                <li className="list-inline-item">
                                                   3 semanas atrás
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                       {/* End Media */}
                                       Lorem ipsum dolor sit amet, consectetur
                                       adipiscing elit.
                                    </blockquote>
                                 </li>
                                 {/* End Review */}
                              </ul>
                              {/* End List Comment */}
                           </div>
                           {/* End Body */}
                        </div>
                     </div>
                     {/* End Row */}

                     {/* Card Footer */}
                     <a
                        className="card-footer text-center"
                        href="./ecommerce-manage-reviews.html"
                     >
                        Ver todos <i className="bi-chevron-right" />
                     </a>
                     {/* End Card Footer */}
                  </div>
               )}
               {/* End Card */}
            </div>
            {/* End Content */}
            {/* Footer */}
            <div className="footer">
               <div className="row justify-content-between align-items-center">
                  <div className="col">
                     <p className="fs-6 mb-0">
                        © Poupanegócios.{" "}
                        <span className="d-none d-sm-inline-block">2022.</span>
                     </p>
                  </div>
                  {/* End Col */}
                  <div className="col-auto">
                     <div className="d-flex justify-content-end">
                        {/* List Separator */}
                        <ul className="list-inline list-separator">
                           <li className="list-inline-item">
                              <a className="list-separator-link" href="#">
                                 FAQ
                              </a>
                           </li>
                           <li className="list-inline-item">
                              <a className="list-separator-link" href="#">
                                 License
                              </a>
                           </li>
                           <li className="list-inline-item">
                              {/* Keyboard Shortcuts Toggle */}
                              <button
                                 className="btn btn-ghost-secondary btn btn-icon btn-ghost-secondary rounded-circle"
                                 type="button"
                                 data-bs-toggle="offcanvas"
                                 data-bs-target="#offcanvasKeyboardShortcuts"
                                 aria-controls="offcanvasKeyboardShortcuts"
                              >
                                 <i className="bi-command" />
                              </button>
                              {/* End Keyboard Shortcuts Toggle */}
                           </li>
                        </ul>
                        {/* End List Separator */}
                     </div>
                  </div>
                  {/* End Col */}
               </div>
               {/* End Row */}
            </div>
            {/* End Footer */}
         </main>

         {/* Modal upload */}
         <div
            className="modal fade"
            id="modal-upload"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 className="modal-title" id="exampleModalLabel">
                        Upload de arquivo
                     </h5>
                     <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     ></button>
                  </div>

                  <div className="modal-body">
                     <form>
                        <div className="card m-4">
                           <div className="card-body">
                              <div className="row">
                                 <div className="col-md-12 col-sm-6">
                                    <input
                                       type="file"
                                       id="arquivo"
                                       className="js-file-attach profile-cover-uploader-input"
                                       onChange={handleUpload}
                                    />
                                    <label
                                       className="profile-cover-uploader-label btn btn-sm btn-white col-md-12 py-5"
                                       for="arquivo"
                                    >
                                       <i className="bi bi-file-earmark-arrow-up"></i>
                                       <span className="d-none d-sm-inline-block ms-1">
                                          {inputUpload?.name ||
                                             "Selecione o arquivo"}
                                       </span>
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>

                  <div className="modal-footer">
                     <button
                        type="button"
                        className="btn btn-white"
                        data-bs-dismiss="modal"
                     >
                        Fechar
                     </button>
                     <button type="button" className="btn btn-primary" disabled={!inputUpload?.name} onClick={handleSalvarUpload}>
                        Salvar
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default CSG;
