function Navbar() {
	return (
		<aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white">
			<div className="navbar-vertical-container">
				<div className="navbar-vertical-footer-offset">
					{/* Logo */}
					<div className="navbar-brand d-flex justify-content-center">
						<img
							src="./assets/svg/logos/logo-mini-poupa.png"
							alt="Logo"
							data-hs-theme-appearance="default"
							style={{ width: "6vw", marginTop: "4vh", opacity: 0.8 }}
						/>
					</div>
					{/* End Logo */}
					{/* Navbar Vertical Toggle */}
					<button
						type="button"
						className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
						style={{ display: "none" }}
					>
						<i
							className="bi-arrow-bar-left navbar-toggler-short-align"
							data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
							data-bs-toggle="tooltip"
							data-bs-placement="right"
							title="Collapse"
						/>
						<i
							className="bi-arrow-bar-right navbar-toggler-full-align"
							data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
							data-bs-toggle="tooltip"
							data-bs-placement="right"
							title="Expand"
						/>
					</button>
					{/* End Navbar Vertical Toggle */}
					{/* Content */}
					<div className="navbar-vertical-content">
						<div id="navbarVerticalMenu" className="nav nav-pills nav-vertical card-navbar-nav">
							<span className="dropdown-header mt-4">Poupanegócios</span>
							<small className="bi-three-dots nav-subtitle-replacer" />
							<ul
								id="navbarSettings"
								className="navbar-nav nav nav-vertical nav-tabs nav-tabs-borderless nav-sm"
							>
								<li className="nav-item">
									<a className="nav-link" href="/operador" data-placement="left">
										<i className="bi-person-bounding-box nav-icon" />
										<span className="nav-link-title">Operacional</span>
									</a>
								</li>
								<div className="dropdown-divider"></div>
								{[
									{ nome: "FAQ", link: "/faq", icone: "bi-patch-question-fill" },
									//{ nome: "Suporte", link: "/suporte", icone: "bi-telephone-plus-fill" },
									{ nome: "Roadmap", link: "/roadmap", icone: "bi-signpost-2-fill" },
									{
										nome: "Documentação",
										link: "/documentacao",
										icone: "bi-archive-fill",
										badge: "TI",
									},
									{
										nome: "Falhas",
										link: "/falhas",
										icone: "bi-exclamation-octagon-fill",
										badge: "TI",
									},
								]
									.sort((a, b) => a.nome.localeCompare(b.nome))
									.map((x) => (
										<a key={x.link} className="nav-link" href={x.link} data-placement="left">
											<i className={`${x.icone} nav-icon`} />
											<span className="nav-link-title">
												{x.nome} {x.badge && <span className="badge bg-poupacred">{x.badge}</span>}
											</span>
										</a>
									))}
							</ul>
						</div>
					</div>
					{/* End Content */}
					{/* Footer */}
					<div className="navbar-vertical-footer" style={{ display: "none" }}>
						<ul className="navbar-vertical-footer-list">
							<li className="navbar-vertical-footer-list-item">
								{/* Other Links */}
								<div className="dropdown dropup">
									<button
										type="button"
										className="btn btn-ghost-secondary btn-icon rounded-circle"
										id="otherLinksDropdown"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										data-bs-dropdown-animation
									>
										<i className="bi-info-circle" />
									</button>
									<div
										className="dropdown-menu navbar-dropdown-menu-borderless"
										aria-labelledby="otherLinksDropdown"
									>
										<span className="dropdown-header">Help</span>
										<a className="dropdown-item" href="#">
											<i className="bi-journals dropdown-item-icon" />
											<span className="text-truncate" title="Resources & tutorials">
												Resources &amp; tutorials
											</span>
										</a>
										<a className="dropdown-item" href="#">
											<i className="bi-command dropdown-item-icon" />
											<span className="text-truncate" title="Keyboard shortcuts">
												Keyboard shortcuts
											</span>
										</a>
										<a className="dropdown-item" href="#">
											<i className="bi-alt dropdown-item-icon" />
											<span className="text-truncate" title="Connect other apps">
												Connect other apps
											</span>
										</a>
										<a className="dropdown-item" href="#">
											<i className="bi-gift dropdown-item-icon" />
											<span className="text-truncate" title="What's new?">
												What's new?
											</span>
										</a>
										<div className="dropdown-divider" />
										<span className="dropdown-header">Contacts</span>
										<a className="dropdown-item" href="#">
											<i className="bi-chat-left-dots dropdown-item-icon" />
											<span className="text-truncate" title="Contact support">
												Contact support
											</span>
										</a>
									</div>
								</div>
								{/* End Other Links */}
							</li>
						</ul>
					</div>
					{/* End Footer */}
				</div>
			</div>
		</aside>
	)
}

export default Navbar
